<template>
    <ul class="sub-menu d-sm-none" v-show='toggle[market]'>
      <li class="level-2">
        <router-link :to="{ name: `${market}.instruments`}">
          {{ $t('components.main_header.charts') }}
        </router-link>
      </li>
      <li class="level-2">
        <router-link :to="{ name: `${market}.video_analysis`}">
          {{ $t('components.main_header.video_analysis') }}
        </router-link>
      </li>
     <!--<li class="level-2">
        <a :href="env.calculator_app_url" target="_blank">
          {{ $t('components.main_header.tools') }}
        </a>
      </li>-->
      <hr>
    </ul>
</template>

<script>
export default {
  props: {
    toggle: Array,
    market: String
  },
  data () {
    return {
      env: {
        calculator_app_url: process.env.VUE_APP_CALCULATOR_APP_URL
      }
    }
  }
}
</script>
