export default {
  common: {
    unknown_error: 'Ups. There was an unknown error, please try again later.',
    please_try_again: 'Please try again.',
    published_at: 'Published at',
    contact_us: 'Contact us',
    free_trial: 'Free Trial',
    new_app_version_available_title: 'New Version Available!',
    new_app_version_available_info: 'Great news! There is a new version available.',
    new_app_version_available_action: 'Click the update button to refresh the dashboard.',
    new_app_version_available_button: 'Update Now',
    new_app_version_available_tip:
      'In case of any issues please use "hard refresh" on your browser. CTRL+Shift+R on Windows and CMD+Shift+R on MAC',
    validations: {
      required: 'This field is required!',
      invalid_email: 'Email is not valid!',
      max_length: 'The field may not be greater than {length} characters!',
      min_length: 'The field must be at least {length} characters!',
      password_confirmed: 'Password does not match!',
      file_image: 'File must me an image!',
      file_size: 'File size must be less than 2MB!',
      integer: 'Must contain only numbers!'
    },
    click: 'Click',
    here: 'here',
    for: 'for',
    full: 'Full',
    access: 'Access'
  },
  meta: {
    title: {
      dashboard: 'Dashboard',
      elliot_wave_school: 'Elliot Wave School',
      log_in: 'Log in',
      sign_up: 'Sign up',
      forgot_password: 'Forgot password',
      reset_password: 'Reset password',
      page_not_found: 'Page not found',
      maintenance_mode: 'Maintenance mode',
      resend_confirmation_instructions: 'Resend confirmation instructions',
      thank_you: 'Thank You',
      blocked: 'Account Blocked',
      forex: {
        charts: 'Forex > Charts',
        charts_intraday: 'Forex > Charts > Intraday Updates',
        video_analysis: 'Forex > Video Analysis'
      },
      crypto: {
        charts: 'Crypto > Charts',
        charts_intraday: 'Crypto > Charts > Intraday Updates',
        video_analysis: 'Crypto > Video Analysis'
      },
      intraday_updates_single: 'Intraday Updates Single',
      profile: {
        account: 'Profile > Account',
        settings: 'Profile > Settings',
        payment_and_subscription: 'Payment & Subscription'
      }
    }
  },
  auth: {
    account_deleted: 'Your account was deleted. Please visit us again. Thank you!',
    blocked: {
      your_account_was_blocked: 'Your Account Was Blocked!',
      please_contact_support: 'Please contact support for more info.',
      support: 'Support'
    },
    login: {
      title: 'Login',
      email_placeholder: 'Email',
      password_placeholder: 'Password',
      remember_me_checkbox: 'Remember me',
      forgot_password: 'Forgot password?',
      login_btn: 'Log in',
      dont_have_an_account: "Don't have an account?",
      did_not_receive_confirmation_instructions: "Didn't receive confirmation instructions?",
      resend_confirmation_instructions: 'Resend',
      sign_up: 'Sign up'
    },
    sign_up: {
      title: 'Sign up',
      email_placeholder: 'Your email',
      password_placeholder: 'Your password',
      repeat_password_placeholder: 'Repeat password',
      news_and_updates_checkbox: 'I want to receive Free Analysis and Sign Up For Webinars',
      i_agree_with: 'I agree with',
      terms_and_conditions: 'Terms and Conditions',
      and: 'and',
      privacy_policy: 'Privacy Policy',
      sign_up_btn: 'Sign up',
      already_have_an_account: 'Already have an account?',
      log_in: 'Log in',
      sign_up_success:
        'A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.'
    },
    sign_out: {
      signed_out: 'You were signed out.'
    },
    forgot_password: {
      title: 'Forgot password',
      email_placeholder: 'Your email',
      send_recovery_link_btn: 'Send recovery link',
      want_to_give_it_another_try: 'Want to give it another try?',
      log_in: 'Log in',
      instruction_text: 'Please type in your email to retrieve the recovery link.',
      recovery_link_sent: 'Great! A recovery link has been send to'
    },
    reset_password: {
      title: 'Set new password',
      new_password_placeholder: 'New password',
      characters_minimum: '(8 characters minimum)',
      repeat_password_placeholder: 'Repeat password',
      set_new_password_btn: 'Set new password',
      already_have_an_account: 'Already have an account?',
      log_in: 'Log in',
      reset_success: 'Your password was updated! Please Log In.',
      password_is_too_short: 'Password is too short (minimum is 8 characters)'
    },
    confirmations: {
      title: 'Resend confirmation instructions',
      email_placeholder: 'your email',
      instruction_text: 'Please type in your email to retrieve confirmation instructions.',
      resend_confirmation_instructions_btn: 'Resend instructions',
      confirmation_instructions_sent: 'Confirmation instructions sent to',
      confirmed: 'Your account was confirmed! Please Log in.',
      log_in: 'Log in',
      sign_up: 'Sign up'
    },
    thank_you_page: {
      wavetraders: 'Wavetraders',
      thank_you: 'Thank you!',
      please_check_your_email: 'Please check your e-mail',
      to_confirm_the_address: 'to confirm the address.',
      go_to_login: 'Go to Login'
    }
  },
  errors: {
    404: {
      title: '404',
      description: 'Ups. The page that you are looking for does not exist.',
      go_to_wavetraders_btn: 'Go to Wavetraders.com'
    }
  },
  maintenance_mode: {
    title: 'Working on it.',
    description: 'We are doing some maintenance at the moment. Please come by a bit later.'
  },
  layouts: {
    default: {
      intraday_updates: 'Intraday Updates',
      slack_channel: 'Slack channel',
      open_slack: 'Open Slack'
    }
  },
  profile: {
    payment_and_subscription: {
      title: 'Payment & Subscription',
      subscriptions: 'Subscriptions',
      subscription: 'Subscription',
      products: 'Products',
      subscribe: 'Subscribe',
      cancel_subscription: 'Cancel Subscription',
      reactivate_subscription: 'Reactivate',
      canceled: 'Canceled',
      valid_until: 'Valid until: ',
      unlimited: 'Unlimited',
      confirm_cancelation: 'Are you sure you want to Cancel your Subscription?',
      confirm_reactivation: 'Your subscription will be Reactivated, continue??',
      was_canceled: 'Your Subscription was Canceled. Thank You and come back soon. Reloading...',
      was_reactivated: 'You subscription was reactivated. Thank You. Reloading...',
      cancelation_error:
        'There was an error in subscription cancelation, please contact support. Thank You.',
      reactivation_error:
        'There was an error in subscription reactivation, please contact support. Thank You.',
      subscribe_now: 'Subscribe Now',
      processing_payment: 'Processing Payment...',
      payment_success: 'Payment Successful. Thank you!',
      subscription_is_being_created: 'Please stand by, your subscription is being created now...',
      not_subscribed: "You currently don't have any subscriptions for {market}, please subscribe.",
      not_subscribed_login: "You currently don't have any subscriptions, please subscribe.",
      not_subscribed_to_ews:
        'To join Elliot Wave School, you need to buy our Elliot Wave School Package.',
      cancel_subscription_header: 'Cancel subscription',
      cancel_subscription_text:
        'If you want to cancel your subscription, please contact us at <a href="mailto: {support_email}">{support_email}</a>.',
      contact_us_mailto_email: 'mailto:{support_email}',
      contact_us: 'Contact Us',
      plus_tax: '+ TAX/VAT',
      buy_now: 'Buy Now',
      price_per: 'per',
      show_details: 'Show Details',
      address: 'Address',
      city: 'City',
      zip: 'Zip',
      coupon_code: 'Coupon Code',
      apply: 'Apply',
      country_placeholder: 'Country'
    },
    account: {
      title: 'Account',
      personal_info_title: 'Personal information',
      name_label: 'Name',
      name_placeholder: 'Your name*',
      surname_label: 'Last Name',
      surname_placeholder: 'Your last name*',
      email_label: 'Email',
      email_placeholder: 'Your email*',
      email_change_info: 'To change email please send your request to <a href="mailto:{info_email}">{info_email}</a>',
      time_zone_placeholder: 'Time Zone',
      save_changes_btn: 'Save changes',
      change_password_title: 'Change password',
      current_password_placeholder: 'Your current password*',
      new_password_placeholder: 'Your new password*',
      repeat_password_placeholder: 'Repeat your new password*',
      change_password_btn: 'Change password',
      update_your_account_data:
        'Hi there! One last step and you are ready to go. Please fill in required Account data.',
      updated: 'Your account was updated.',
      password_updated: 'Your password was updated.',
      address_placeholder: 'Address*',
      city_placeholder: 'City*',
      country_placeholder: 'Country*',
      zip_placeholder: 'ZIP / Postal code*',
      profile_photo: {
        deleted_confirm: 'Your profile photo will be deleted! Continue?',
        deleted: 'Profile photo was removed. You can add another one ;)',
        updated: 'Your profile photo was updated.'
      },
      password: {
        updated: 'Your password was updated, please login again.'
      },
      delete_account: 'Delete Account',
      delete_account_text:
        'If you want to delete the account because you experienced some issues, please don\'t hesitate to contact us at <a href="mailto: {support_email}">{support_email}</a> so our team can work with you to resolve those issues!',
      you_can: 'You can',
      download_your_information: 'download your information',
      or: 'or',
      delete_the_account: 'delete the account',
      step_can_not_reversed: '. This step can not be reversed.',
      account_will_be_deleted: 'Your account will be permanently deleted! Are you sure?',
      yes: 'Yes',
      no: 'No'
    },
    settings: {
      title: 'Notifications Settings',
      notification_settings: 'Email Notification Settings',
      all_email_notifications: 'All Email notifications',
      updated: 'Settings were updated.',
      primary_analysis: 'Primary Analysis',
      intraday_updates: 'Intraday Updates',
      trading_notes: 'Trading Notes'
    },
    your_account: 'Your Account',
    notifications_settings: 'Notifications Settings',
    logout: 'Logout'
  },
  components: {
    instruments_bar: {
      search_placeholder: 'Search',
      all_items: 'ALL',
      add_all_to_favourites: 'Add ALL To FAV',
      all_favourite_items: 'ALL FAV'
    },
    user_notifications: {
      notifications: 'Notifications'
    },
    intraday_updates: {
      intraday_updates: 'Intraday Updates',
      for_my_favorites: 'for my Favorites',
      charts_updates: 'Charts Updates',
      filter: 'Filter',
      date: 'Date',
      search_placeholder: 'Search',
      apply_filter: 'Apply Filter',
      successfully_updated: 'Successfully updated',
      show_more: 'Show more',
      show_less: 'Show less',
      primary_analysis: 'Primary Analysis',
      trading_notes_updates_for: 'Trading note updates for',
      chart_update_for: 'Chart Update For'
    },
    instruments: {
      view_intraday_updates: 'View Intraday Updates',
      sort_by: 'Sort By',
      trading_note: 'Trading Note',
      minutes_ago: 'minutes ago',
      show_updates: 'Show Updates',
      close_updates: 'Close Updates'
    },
    elliot_wave_school: {
      header: {
        ew: 'EW',
        elliott_wave_academy: 'Elliott Wave Academy'
      },
      detail: {
        play_lesson: 'Play Lesson'
      }
    },
    videos_list: {
      sort_by: 'Sort By'
    },
    main_header: {
      favourites: 'Favourites',
      forex: 'Forex',
      charts: 'Charts',
      video_analysis_2: 'Video Analysis 2',
      video_analysis: 'Video Analysis',
      tools: 'Tools',
      crypto: 'Crypto',
      elliot_wave_school: 'Elliot Wave School',
      profile: 'Profile',
      settings: 'Notifications Settings',
      light: 'Light',
      dark: 'Dark'
    },
    sub_header: {
      charts: 'Charts',
      video_analysis_2: 'Video Analysis 2',
      video_analysis: 'Video Analysis',
      tools: 'Tools',
      settings: 'Notifications Settings',
      payment_and_subscription: 'Payment & Subscription',
      account: 'Account'
    }
  },
  popup: {
    subscribe_button: 'Subscribe'
  }
}
