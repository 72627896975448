import Vue from 'vue'
import i18n from '../i18n'

import InstrumentsComponent from '../components/market/Instruments.vue'
import VideoAnalysisComponent from '../components/market/VideoAnalysis.vue'
import IntradayUpdatesPopupList from '../components/intraday_updates/PopupList.vue'

import ElliotWaveSchoolComponent from '../components/elliot_wave_school/List.vue'
import ElliotWaveSchoolDetailComponent from '../components/elliot_wave_school/Detail.vue'

function loadView (view) {
  return () => import('../views/' + view + '.vue')
}

function market_routes (market) {
  return {
    path: `/${market}`,
    name: market,
    redirect: { name: `${market}.instruments` },
    component: loadView('layouts/Market'),
    meta: {
      market: market
    },
    beforeEnter: (to, from, next) => {
      Vue.auth.load().then(() => {
        if (Vue.auth.check()) {
          if (Vue.auth.user().blocked) {
            next({ name: 'blocked' })
          } else {
            next()
          }
        }
      })
    },
    children: [
      {
        path: 'charts/:instrument_slug?',
        name: `${market}.instruments`,
        component: InstrumentsComponent,
        meta: {
          title: i18n.t(`meta.title.${market}.charts`),
          auth: true
        },
        children: [
          {
            path: 'intraday-updates/:intraday_updates_instrument_slug',
            name: `${market}.instruments.intraday-updates`,
            component: IntradayUpdatesPopupList,
            meta: {
              title: i18n.t(`meta.title.${market}.charts_intraday`),
              auth: true
            }
          }
        ]
      }, {
        path: 'video_analysis/:id?',
        name: `${market}.video_analysis`,
        component: VideoAnalysisComponent,
        meta: {
          title: i18n.t(`meta.title.${market}.video_analysis`),
          auth: true
        }
      }
    ]
  }
}

export default [
  {
    path: '/',
    name: 'default',
    beforeEnter: (to, from, next) => {
      Vue.auth.load().then(() => {
        if (Vue.auth.check()) {
          const markets = Vue.auth.user().subscriptions.flatMap(s => (s.markets))

          if (Vue.auth.user().blocked) {
            next({ name: 'blocked' })
          } else if (markets.length > 0) {
            next({ name: markets[0].slug })
          } else if (Vue.auth.user().open_door && Vue.auth.user().open_door_accessed) {
            next('/forex/charts')
          } else {
            next({ name: 'profile.payment_and_subscription' })
          }
        } else {
          next({ name: 'login' })
        }
      })
    }
  },
  market_routes('forex'),
  market_routes('crypto'),
  {
    path: '/elliot-wave-school',
    name: 'elliot-wave-school',
    component: ElliotWaveSchoolComponent,
    meta: {
      layout: 'elliot-wave-school',
      title: i18n.t('meta.title.elliot_wave_school'),
      auth: true
    },
    children: [{
      path: ':svp_playlist_id',
      name: 'elliot-wave-school.detail',
      component: ElliotWaveSchoolDetailComponent,
      meta: {
        title: i18n.t('meta.title.elliot_wave_school'),
        auth: true
      }
    }]
  }, {
    path: '/profile',
    name: 'profile',
    redirect: '/profile/settings',
    component: loadView('layouts/Profile'),
    children: [{
      path: 'account',
      name: 'profile.account',
      component: loadView('profile/Account'),
      meta: {
        title: i18n.t('meta.title.profile.account'),
        auth: true
      }
    }, {
      path: 'settings',
      name: 'profile.settings',
      component: loadView('profile/Settings'),
      meta: {
        title: i18n.t('meta.title.profile.settings'),
        auth: true
      }
    }, {
      path: 'payment_and_subscription',
      name: 'profile.payment_and_subscription',
      component: loadView('profile/PaymentAndSubscription'),
      meta: {
        title: i18n.t('meta.title.profile.payment_and_subscription'),
        auth: true
      }
    }]
  }, {
    path: '/login',
    name: 'login',
    component: loadView('auth/Login'),
    meta: {
      layout: 'auth',
      bodyClass: 'auth',
      title: i18n.t('meta.title.log_in')
    }
  }, {
    path: '/sign-up',
    name: 'sign-up',
    component: loadView('auth/SignUp'),
    meta: {
      layout: 'auth',
      bodyClass: 'auth',
      title: i18n.t('meta.title.sign_up')
    }
  }, {
    path: '/forgot-password',
    name: 'forgot-password',
    component: loadView('auth/ForgotPassword'),
    meta: {
      layout: 'auth',
      bodyClass: 'auth',
      title: i18n.t('meta.title.forgot_password')
    }
  }, {
    path: '/reset-password',
    name: 'reset-password',
    component: loadView('auth/ResetPassword'),
    meta: {
      layout: 'auth',
      bodyClass: 'auth',
      title: i18n.t('meta.title.reset_password')
    }
  }, {
    path: '/resend-confirmation-instructions',
    name: 'resend-confirmation-instructions',
    component: loadView('auth/ResendConfirmationInstructions'),
    meta: {
      layout: 'auth',
      bodyClass: 'auth',
      title: i18n.t('meta.title.resend_confirmation_instructions')
    }
  }, {
    path: '/thank-you-for-subscribing',
    name: 'thank-you-for-subscribing',
    component: loadView('auth/ThankYouForSubscribingPage'),
    query: { email: '' },
    props: true,
    meta: {
      layout: 'auth',
      title: i18n.t('meta.title.thank_you')
    }
  }, {
    path: '/404',
    name: 'error-404',
    component: loadView('errors/404'),
    meta: {
      layout: 'auth',
      bodyClass: 'auth error',
      title: i18n.t('meta.title.page_not_found')
    }
  }, {
    path: '/maintenance-mode',
    name: 'maintenance-mode',
    component: loadView('MaintenanceMode'),
    meta: {
      layout: 'auth',
      bodyClass: 'auth maintenance-mode',
      title: i18n.t('meta.title.maintenance_mode')
    }
  }, {
    path: '/blocked',
    name: 'blocked',
    component: loadView('auth/Blocked'),
    meta: {
      layout: 'auth',
      bodyClass: 'auth error',
      title: i18n.t('meta.title.blocked')
    }
  }, {
    path: '*',
    component: loadView('errors/404'),
    meta: {
      layout: 'auth',
      bodyClass: 'auth error',
      title: i18n.t('meta.title.page_not_found')
    }
  }
]
