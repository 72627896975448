import Vue from 'vue'
import Vuex from 'vuex'

import countries from './modules/countries/store.js'
import notifications from './modules/notifications/store.js'
import market from './modules/market/store.js'
import profile from './modules/profile/store.js'
import time_zones from './modules/time_zones/store.js'
import intraday_updates from './modules/intraday_updates/store.js'
import elliot_wave_schools from './modules/elliot_wave_schools/store.js'
import application_settings from './modules/application_settings/store.js'
import products from './modules/products/store.js'
import subscription_plans from './modules/subscription_plans/store.js'
import trading_notes from './modules/trading_notes/store.js'
import dynamic_contents from './modules/dynamic_contents/store.js'
import updates from './modules/updates/store.js'
import user_theme from './modules/user_theme/store.js'
import wisepops from './modules/wisepops/store.js'
import blocked from './modules/blocked/store.js'
import subscribe_popup from './modules/subscribe_popup/store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    profile: profile,
    countries: countries,
    notifications: notifications,
    market: market,
    time_zones: time_zones,
    intraday_updates: intraday_updates,
    elliot_wave_schools: elliot_wave_schools,
    application_settings: application_settings,
    products: products,
    subscription_plans: subscription_plans,
    trading_notes: trading_notes,
    dynamic_contents: dynamic_contents,
    updates: updates,
    user_theme: user_theme,
    wisepops: wisepops,
    blocked: blocked,
    subscribe_popup: subscribe_popup
  }
})
